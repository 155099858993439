<template>
  <v-card v-if="reviews.length > 0" class="pa-3 mt-3">
    <p class="headline font-weight-medium grey--text mb-2">Reviews</p>
    <v-row v-for="review in reviews" :key="review.index" justify="center" no-gutters>
      <v-col cols="12">
        <v-divider></v-divider>
        <p :class="$vuetify.breakpoint.mdAndUp ? 'font-weight-bold primary--text pt-3 mb-0' : 'body-2 font-weight-bold primary--text pt-3 mb-0'">{{ review.problemTitle }}</p>
        <v-card-actions class="pa-0 mb-3">
          <v-rating
            :value="review.rating"
            small
            color="primary"
            background-color="bgGrey"
            empty-icon="star"
            dense
            readonly
          ></v-rating>
          <span class="grey--text caption">
            {{ review.createdAt | localDate }}
          </span>
        </v-card-actions>
      </v-col>
      <v-col cols="12">
        <p v-for="line in review.comment.split('\n')" :key="line.key" :class="$vuetify.breakpoint.smAndDown ? 'body-2' : ''">{{ line }}</p>
      </v-col>
    </v-row>
    <!-- <v-layout row wrap>
      <v-flex xs12 px-3 mt-4>
        <p class="headline font-weight-medium grey--text ma-0">Reviews</p>
        <v-layout row wrap v-for="review in reviews" :key="review.index">
          <v-flex xs12 pt-3>
            <v-divider></v-divider>
            <p :class="$vuetify.breakpoint.mdAndUp ? 'font-weight-bold primary--text pt-3' : 'body-2 font-weight-bold primary--text pt-3'">{{ review.problemTitle }}</p>
            <v-card-actions class="pl-0 pt-0" style="margin-top: -10px;">
              <v-rating
                :small="$vuetify.breakpoint.smAndDown"
                :value="review.rating"
                color="primary"
                background-color="grey"
                empty-icon="star"
                class="pb-1"
                dense
                readonly
              ></v-rating>
              <span :class="$vuetify.breakpoint.mdAndUp ? 'font-weight-bold mx-2' : 'font-weight-bold mx-2 body-2'">
                {{ review.rating }} Stars
              </span>
              <span :class="$vuetify.breakpoint.mdAndUp ? 'grey--text mx-2' : 'grey--text mx-2 body-2'">
                {{ review.createdAt | localDate }}
              </span>
            </v-card-actions>
            <v-flex xs10 pl-0>
              <p v-for="line in review.comment.split('\n')" :key="line.key" :class="$vuetify.breakpoint.smAndDown ? 'body-2' : ''">{{ line }}</p>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout> -->
    <v-layout v-show="isFetching" row wrap>
      <v-flex xs12 px-3 pt-4 pb-3>
        <content-placeholders :rounded="true">
          <content-placeholders-heading :img="false"/>
          <content-placeholders-text :lines="2"/>
        </content-placeholders>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import UserService from '@/services/UserService'
import dateToWords from '@/mixins/momentDateToWordsMixin'

export default {
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  mixins: [
    dateToWords
  ],
  data () {
    return {
      isFetching: false,
      bottom: false,
      reviews: [],
      page: 1,
      pages: 0
    }
  },
  methods: {
    initReviews () {
      this.isFetching = true
      UserService.getReviews({
        userId: this.userId,
        page: this.page
      })
        .then(res => {
          this.pages = res.data.pages
          for (let i = 0; i < res.data.docs.length; i++) {
            this.reviews.push(res.data.docs[i])
          }
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    bottomVisible () {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight / 2
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage
    }
  },
  watch: {
    bottom (bottom) {
      if (bottom) {
        if (this.pages > this.page) {
          this.page = this.page + 1
          this.initReviews()
        }
      }
    }
  },
  created () {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
    this.initReviews()
  }
}
</script>
