<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12" md="10" lg="5" xl="4" :class="{'pa-3' : !$vuetify.breakpoint.smAndDown}">
      <v-card v-if="$vuetify.breakpoint.mdAndDown" class="mb-2">
        <v-layout text-center>
          <!-- <v-flex xs12 px-3 py-2>
            <div v-if="!isFetching">
              <p class="mb-0">Your Wallet</p>
              <span class="headline font-weight-medium primary--text">{{ wallet | toLocaleString }}</span>
            </div>
            <div v-else class="py-2">
              <v-progress-circular
                indeterminate
                size="16"
                width="2"
              ></v-progress-circular>
            </div>
          </v-flex> -->
        </v-layout>
      </v-card>
      <v-card class="pa-3">
        <v-row v-if="!isFetching" align="center" no-gutters>
          <v-col v-if="!user.isLawyerVerified" cols="12" class="mb-2">
            <v-card-actions class="warning">
              <v-icon class="white--text">assignment</v-icon>
              <span class="pl-2 caption white--text">We are now verifying your account. You will be able to submit proposals once your profile is approved.</span>
            </v-card-actions>
          </v-col>
          
          <!-- Mobile View Profile Image -->
          <v-flex xs12 py-2 v-if="$vuetify.breakpoint.mdAndDown" class="text-center">
            <v-avatar size="100">
              <v-img
                :src="user.imageUrl"
                style="border-radius: 8%"
                aspect-ratio="1"
                alt="avatar"
              ></v-img>
            </v-avatar>
          </v-flex>
          <v-flex xs12 sm10 class="text-center" v-if="$vuetify.breakpoint.mdAndDown">
            <span class="title">{{ user.fullName }}</span>
            <v-flex>
              <v-rating
                readonly
                dense
                color="primary"
                background-color="bgGrey"
                empty-icon="star"
                :value="user.reviews | calculateRating"
              ></v-rating>
              <span class="grey--text body-2">
                {{ user.reviews | calculateRating }} ({{ user.reviews.length }} reviews)
              </span>
            </v-flex>
            <span v-if="$store.getters['user/user'].user.lawOffice" class="subheading font-weight-medium semi-black">
              <v-icon small class="pr-2">fa-balance-scale</v-icon>{{ user.lawOffice }}
            </span>
          </v-flex>
          <!-- End of Mobile View Profile Image -->

          <v-col v-if="!$vuetify.breakpoint.mdAndDown" cols="3" class="text-center pb-3">
            <v-avatar size="110">
              <v-img
                :src="user.imageUrl"
                style="border-radius: 8%"
                aspect-ratio="1"
                alt="avatar"
              ></v-img>
            </v-avatar>
          </v-col>

          <v-col v-if="!$vuetify.breakpoint.mdAndDown" cols="8">
            <span class="title">{{ user.fullName }}</span>
            <v-row align="center" no-gutters>
              <v-col cols="12">
                <v-card-actions class="pa-0">
                  <v-rating
                    :value="user.reviews | calculateRating"
                    color="primary"
                    background-color="bgGrey"
                    empty-icon="star"
                    readonly
                    dense
                  ></v-rating>
                  <span class="grey--text">
                    {{ user.reviews | calculateRating }}
                  </span>
                  <span class="caption grey--text pl-1">
                    ({{ user.reviews.length }} reviews)
                  </span>
                </v-card-actions>
              </v-col>
              <v-col cols="12">
                <span v-if="$store.getters['user/user'].user.lawOffice" class="subheading font-weight-medium semi-black">
                  <v-icon small class="pr-2">fa-balance-scale</v-icon>{{ user.lawOffice }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" pa-0 my-2  class="text-center">
            <v-chip
              label
              dark
              color="primary"
              class="mr-1 mt-1"
              v-for="specs in user.specialization" :key="specs.index"
            >{{ specs }}</v-chip>
          </v-col>

          <v-col v-else cols="12" pa-0 my-2 >
            <v-chip
              label
              dark
              color="primary"
              class="mr-1"
              v-for="specs in user.specialization" :key="specs.index"
            >{{ specs }}</v-chip>
          </v-col>

          <v-col v-if="user.description" class="12">
            <p class="title grey--text mb-0">Overview</p>
            <p v-for="line in user.description.split('\n')" :key="line.key">{{ line }}</p>
          </v-col>
          <v-col cols="12">
            <p class="title grey--text mb-0 pb-0">Contacts</p>
            <v-list class="py-0">
              <div>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">mail</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="contacts">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">phone</v-icon>
                  </v-list-item-action>
                  <v-list-item-content v-for="number in contacts" :key="number.key">
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div v-if="completeAddress">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">location_on</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ completeAddress }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-col>
        </v-row>
        <v-layout v-else>
          <v-flex xs12 px-3>
            <content-placeholders :rounded="true">
              <content-placeholders-heading :img="true"/>
              <content-placeholders-text :lines="2"/>
              <content-placeholders-text class="mt-5" :lines="3"/>
            </content-placeholders>
          </v-flex>
        </v-layout>
      </v-card>
      <Reviews v-if="user._id" :userId="user._id"/>
    </v-col>
    <v-col v-if="!$vuetify.breakpoint.mdAndDown" cols="2">
      <v-row no-gutters>
        <v-col cols="12" class="pt-3">
          <!-- <v-card>
            <v-col cols="12" class="px-3 py-2">
              <span v-if="!isFetching" class="text-md-subtitle-1 font-weight-medium">Your wallet
                <span class="headline font-weight-medium primary--text d-flex">{{ wallet | toLocaleString }}</span>
              </span>
              <div v-else class="text-center py-2">
                <v-progress-circular
                  indeterminate
                  size="16"
                  width="2"
                ></v-progress-circular>
              </div>
            </v-col>
            <v-divider />
          </v-card> -->
          <!-- <v-btn depressed color="primary" block large :to="{ name: 'Transactions', params: { userId: this.$store.getters['user/user'].user._id }}" class="my-2">Transactions</v-btn> -->
          <v-btn depressed color="primary" block large to="/settings/profile">Edit Profile</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-speed-dial v-if="$vuetify.breakpoint.mdAndDown" fixed bottom right direction="top">
      <template v-slot:activator>
        <v-btn color="primary" @click="opened = !opened" fab>
          <v-icon v-if="!opened">more_vert</v-icon>
          <v-icon v-else>close</v-icon>
        </v-btn>
      </template>
      <v-btn fab to="/settings/profile">
        <v-icon color="primary">edit</v-icon>
      </v-btn>
      <!-- <v-btn fab to="/transactions">
        <v-icon color="primary">account_balance_wallet</v-icon>
      </v-btn> -->
    </v-speed-dial>
  </v-row>
</template>

<script>
import Reviews from '@/components/UserComponent/LawyerComponent/Reviews'
import dateToWords from '@/mixins/momentDateToWordsMixin'
import calculateRating from '@/mixins/calculateRatingMixin'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import TransactionService from '@/services/TransactionService'
import moment from 'moment'

export default {
  name: 'LawyerOverview',
  components: {
    Reviews
  },
  mixins: [
    dateToWords,
    calculateRating,
    toLocaleString
  ],
  data () {
    return {
      opened: false,
      wallet: ''
    }
  },
  methods: {
    getTransactions () {
      TransactionService.getTransactions({
        userId: this.$store.getters['user/user'].user._id
      })
        .then(res => {
          this.wallet = res.data.wallet
        })
    }
  },
  computed: {
    user () {
      return this.$store.getters['user/user'].user
    },
    hasListing () {
      if (this.user.listingEnds) {
        return moment(this.user.listingEnds).isSameOrAfter(new Date())
      } else {
        return false
      }
    },
    isFetching () {
      return this.$store.getters['user/isFetching']
    },
    completeAddress: {
      get () {
        return this.$store.getters['user/user'].user.completeAddress
      }
    },
    contacts: {
      get () {
        return this.$store.getters['user/user'].user.contacts
      }
    },
    education: {
      get () {
        return this.$store.getters['user/user'].user.education
      }
    },
    email: {
      get () {
        return this.$store.getters['user/user'].user.email
      }
    }
  },
  mounted () {
    this.getTransactions()
    this.$store.dispatch('user/SET_LOGGED_USER')
  }
}
</script>
